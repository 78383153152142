import React, { useState } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css';
import Header from '../header/header';
import LabeledInput from '../inputs/input';
import AuthButt from '../button/AuthButt';
import LinksForm from './linksArr';
import ClipLoader from 'react-spinners/ClipLoader';
import { Update } from '../../apis';
import { useNavigate } from 'react-router-dom';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // Sleep function

const Register4 = ({ formData, setFormData, index, setIndex, isEdit }) => {
    const navigate = useNavigate();
    const [links, setLinks] = useState(formData.links);
    const [errors, setErrors] = useState({}); // Error state for validation messages
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: '' }); // Clear error message on input change
    };

    const doUpdate = async (e) => {
        e.preventDefault();
        // Validate the links before proceeding
        if (validateLinks()) {
            console.log(links);
            var newFormData = { ...formData, links };
            setFormData(newFormData);
            setLoading(true);
            try {
                await sleep(500); // Sleep for 0.5 seconds before the update
                console.log(newFormData);
                await Update(newFormData);
                localStorage.removeItem('addOns');
                setLoading(false);
                navigate('/myPlan');
            } catch (error) {
                var newErrors = { ...errors, update: error.message };
                setErrors(newErrors);
                console.log(error);
            }
        } else {
            return;
        }
    };

    const isValidURL = (url) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
        return urlPattern.test(url);
    };

    const validateLinks = () => {
        const newErrors = {}; // Object to store error messages
        const { github, linkedin } = formData;

        // Validate GitHub link
        const githubPattern = /^(https?:\/\/)?(www\.)?github\.com\/[A-Za-z0-9._%-]+\/?[A-Za-z0-9._%-]*$/;
        if (github && !githubPattern.test(github)) {
            newErrors.github = "Please enter a valid GitHub link.";
        }

        // Validate LinkedIn link
        const linkedinPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9._%-]+\/?$/;
        if (linkedin && !linkedinPattern.test(linkedin)) {
            newErrors.linkedin = "Please enter a valid LinkedIn link.";
        }

        // Validate additional links
        for (let i = 0; i < links.length; i++) {
            if (!isValidURL(links[i].linkUrl)) {
                newErrors.common = "Please enter valid link URLs.";
            }
            if (!links[i].linkName) {
                newErrors.common = "Link name cannot be empty.";
            }
        }

        // Update errors state
        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = (e) => {
        e.preventDefault();

        // Validate the links before proceeding
        if (validateLinks()) {
            const newIndex = index + 1;
            setFormData({
                ...formData,
                links,
            });
            setIndex(newIndex);
        }
    };

    const handlePrev = (e) => {
        e.preventDefault();
        const newIndex = index - 1;
        setFormData({
            ...formData,
            links,
        });
        setIndex(newIndex);
    };

    return (
        <div className="main-box">
            <form>
                <h1 className='flex justify-center items-center text-bold'>CoverUp</h1>
                <div className="form-group">
                    <LabeledInput
                        label={"GitHub Link"}
                        type='text'
                        name='github'
                        value={formData.github}
                        onChange={handleChange}
                    />
                    {errors.github && <span className="error">{errors.github}</span>} {/* Display GitHub error */}
                </div>

                <div className="form-group last">
                    <LabeledInput
                        className="last"
                        label={"LinkedIn Link"}
                        type='text'
                        name='linkedin'
                        value={formData.linkedin}
                        onChange={handleChange}
                    />
                    {errors.linkedin && <span className="error">{errors.linkedin}</span>} {/* Display LinkedIn error */}
                </div>

                <LinksForm links={links} setLinks={setLinks} />

                <div className='btn-grp'>
                    {
                        !isEdit && <AuthButt text="Next" onClick={handleNext}></AuthButt>
                    }
                    {
                        isEdit && <>
                            {loading ? (
                                <AuthButt text={<ClipLoader color="white" size={9} />} />
                            ) : (
                                <AuthButt text="Update user profile" onClick={doUpdate} />
                            )}
                        </>
                    }
                    <AuthButt text="Prev" onClick={handlePrev}></AuthButt>
                </div>
            </form>
        </div>
    );
};

export default Register4;
