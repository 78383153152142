import React from "react";
import "./UserProfile.css"; // Ensure to include your styles here

import { useNavigate } from 'react-router-dom';

const UserProfile = ({ userData }) => {
const navigate = useNavigate()
  return (
    <div className="profile-container">
      <div className="edit-button-container">
        <button className="edit-button" onClick={() => {
            navigate('/editUserInfo')
        }}>
          Edit
        </button>
      </div>
      <h2 className="profile-header">
        {userData.firstname} {userData.lastname}
      </h2>
      <div className="profile-info">
        <p><strong>Email:</strong> {userData.email}</p>
        <p><strong>Phone:</strong> {userData.phone}</p>
        <p><strong>Experience:</strong></p>
        <p>{userData.experience}</p>
      </div>

      {/* Social Links section */}
      {userData.github || userData.linkedin ? (
        <div className="profile-links">
          <h3>Social Links</h3>
          {userData.github && (
            <p>
              <strong>GitHub:</strong> <a href={userData.github} target="_blank" rel="noopener noreferrer">{userData.github}</a>
            </p>
          )}
          {userData.linkedin && (
            <p>
              <strong>LinkedIn:</strong> <a href={userData.linkedin} target="_blank" rel="noopener noreferrer">{userData.linkedin}</a>
            </p>
          )}
        </div>
      ) : null}

      {/* Additional Links section */}
      {userData.links && userData.links.length > 0 && (
        <div>
          <h4>Additional Links</h4>
          <ul>
            {userData.links.map((link, index) => (
              <li key={index}>
                <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">
                  {link.linkName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="profile-skills">
        <h3>Skills</h3>
        {userData.skills && userData.skills.length > 0 ? (
          <ul className="skills-list">
            {userData.skills.map((skill, index) => (
              <li key={index} className="skill-box">{skill}</li>
            ))}
          </ul>
        ) : (
          <p>No skills added yet</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
