import React, { useState, useEffect } from 'react';
import './loginForm.css'; // Import the CSS file
import './styles.css';
import LabeledInput from './inputs/input';
import AuthButt from './button/AuthButt';
import { useNavigate } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader';
const LoginForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '', 
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const { email, password } = formData;

    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }

    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://backend-coverletter-ea2d7481d56b.herokuapp.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();

        // Store access and refresh tokens in localStorage
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('username', formData.email);
        // Navigate to the dashboard on successful login
        navigate('/');
      } else {
        const errorData = await response.json();
        console.error('Error logging in:', errorData);
        alert('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Request failed:', error);
      alert('Network error. Please check your connection and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <div>
      <div className='auth-box'>
      <div className="main-box">
      <form onSubmit={handleSubmit}>
        <h1 className='flex justify-center items-center'>CoverUp</h1>
        <div className="form-group">
          <LabeledInput
            label={"Email"}
            type='text'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required={true}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group last">
          <LabeledInput
            className="last"
            label={"Password"}
            type='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            required={true}
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>

        <div className='btn-grp'>
          <div className='flex justify-end'>
            New User?<a href='/register'>Register</a>
          </div>
          <AuthButt text={loading ? <ClipLoader color="white" size={11} /> : "Login"} onClick={handleSubmit} disabled={loading} />
          
        </div>
      </form>
    </div>
      </div>
    </div>
  );
};

export default LoginForm;
