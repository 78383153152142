import React, { useEffect, useState } from "react";
import Header from "../header/header";
import PlanCard from "./planCard";
import { getUserInfo } from "../../apis";
import BuyLetters from "../payment/tokensForm";
import RazorpayButtonSubscribe from "../payment/razorpay";
import ClipLoader from 'react-spinners/ClipLoader';
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios'
import UserProfile from "../../components/userProfile/userProfile";
import { getValidAccessToken } from "../../apis";

import { BASIC_PLAN, ADVANCED_PLAN, TRIAL_PLAN } from "../constants";


const SubscriptionPlan = () => {
    const [currentPlan, setCurrentPlan] = useState(null);
    const [userInfo, setUserInfo] = useState(null)
    const handleUpgrade = () => {

    };
    const [balance, setBalance] = useState(0)
    const [showBuyTokens, setShowBuyTokens] = useState(false)
    useEffect(() => {

        (async () => {
            const newUserInfo = await getUserInfo("")
            setUserInfo(newUserInfo)
            setBalance(newUserInfo.balance)
        })()

    }, [])

    const allPlans = [
        {
            title: TRIAL_PLAN,
            price: 0,
            features: [
                {
                    text: "3 Free letters",
                    icon: "Group Message.png"
                },
            ]
        },
        {
            title: BASIC_PLAN,
            price: 50,
            features: [{
                text: "40 letters per month",
                icon: "Group Message.png"
            }]
        },
        {
            title: ADVANCED_PLAN,
            price: 100,
            features: [
                {
                    text: "100 letters per month",
                    icon: "Group Message.png"
                },
                {
                    text: "Customizable AddOns",
                    icon: "Wrench.png"
                }

            ]
        }
    ]

    return (
        <div className="outer-box  items-center justify-center">
            <Header isLoggedIn={true} showFromMain={showBuyTokens} setShowFromMain={setShowBuyTokens} setBalance={setBalance} balance={balance}></Header>
            
            {userInfo && <UserProfile userData={userInfo}></UserProfile>}
            <div className="curr-plan flex items-center flex-row">

                {
                    userInfo && <>
                        
                        <div className="balance bg-[#FAF8B3] p-[1rem] w-[300px] flex items-center flex-col h-[18rem]">
                            <p className="text-[6rem] m-0 text-[#5C5C5C]">{balance}</p>
                            <p className="mt-[-1rem] mb-[1rem] text-[#5C5C5C]">Letters left</p>
                            <button className="bg-[#9D842A] text-white" onClick={() => {
                                setShowBuyTokens(true)
                            }}>Buy more</button>
                        </div>
                        {
                            allPlans.map((plan) => 
                                userInfo.plan === plan.title && <PlanCard title={plan.title} price={plan.price} features={plan.features} currPlan={true}></PlanCard>
                            )
                        }

                    </>
                }
            </div>


            {userInfo && <h1 className="text-[#AEAEAE] mt-[4rem]">Other Plans</h1>}
            <div className="flex flex-row">
                {
                    userInfo && <>
                    
                    {
                            allPlans.map((plan) => 
                                userInfo.plan !== plan.title && plan.title !== TRIAL_PLAN &&  <PlanCard title={plan.title} price={plan.price} features={plan.features}></PlanCard>
                            )
                        }
                    </>
                }
                {
                    !userInfo && <h1 className="mt-[40vh]"><ThreeDots 
                    height="90"
                    width="90"
                    color="black" // You can change this to any color
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  /></h1>
                }
            </div>
        </div>
    );
};

export default SubscriptionPlan;
