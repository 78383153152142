import React from 'react';
import './featureCard.css'; // Import your styles

const FeatureCard = ({ iconUrl, title, description }) => {
  return (
    <div className="feature-card">
      <img className="feature-card-icon" src={iconUrl}/>
      <div className="feature-card-content">
        <h3 className="feature-card-title">{title}</h3>
        <p className="feature-card-description flex justify-center items-center mt-[1rem] text-center">{description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
