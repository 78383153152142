import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-[1rem] w-full mt-[2rem] h-fit flex justify-start flex-col">
      <div className="container mx-auto text-center flex flex-col justify-center gap-6">
        <p>
          © {new Date().getFullYear()} CoverUp. All rights reserved.
        </p>
        <p>
          Contact us: <a href="mailto:arankeparth1912@gmail.com" className="text-gray-400 hover:text-white">arankeparth1912@gmail.com</a> | Phone: <a href="tel:+917057658471" className="text-gray-400 hover:text-white">+91 7057658471</a><div  className="text-gray-400">
          Ashwini colony, samangoan road, nashik road, nashik, maharashtra, 422101 
          </div>
        </p>
        <div className="flex justify-center space-x-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f text-gray-400 hover:text-white"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter text-gray-400 hover:text-white"></i>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in text-gray-400 hover:text-white"></i>
          </a>
        </div>
      </div>

      {/* Adding the required policies */}
      <div className="container mx-auto text-center flex flex-col gap-3 items-center justify-center">
        <a href="/terms" className="text-gray-400 hover:text-white">Terms and Conditions</a>
        <a href="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</a>
        <a href="/refund" className="text-gray-400 hover:text-white">Refunds/Cancellations</a>
        <a href="/shipping" className="text-gray-400 hover:text-white">Shipping Policy</a>
        <a href="/pricing" className="text-gray-400 hover:text-white">Pricing</a>
      </div>
    </footer>
  );
};

export default Footer;
