// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Inria Sans', sans-serif;
}

.header {
  background-color: #000000;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.header-title {
  font-size: 2rem;
}

.header-nav {
  display: flex;
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 3rem;
  font-weight: 300;
}

.header-menu li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.header-menu li a:hover {
  color: rgb(194, 194, 194);
}

h1 {
    margin: 0;
}

.popup {
  position: fixed;
  top:0;
  left:0;
  background-color: white;
}

a {
  transition: all 0.1s;
}

a:hover {
  text-decoration: none;
}

.header-title:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/comps/header/header.css"],"names":[],"mappings":"AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,SAAS;AACb;;AAEA;EACE,eAAe;EACf,KAAK;EACL,MAAM;EACN,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&display=swap');\r\n\r\n* {\r\n  font-family: 'Inria Sans', sans-serif;\r\n}\r\n\r\n.header {\r\n  background-color: #000000;\r\n  color: white;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  height: 4rem;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  gap: 5rem;\r\n}\r\n\r\n.header-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n  padding: 0 20px;\r\n  width: 100%;\r\n}\r\n\r\n.header-title {\r\n  font-size: 2rem;\r\n}\r\n\r\n.header-nav {\r\n  display: flex;\r\n}\r\n\r\n.header-menu {\r\n  list-style: none;\r\n  display: flex;\r\n  gap: 3rem;\r\n  font-weight: 300;\r\n}\r\n\r\n.header-menu li a {\r\n  color: white;\r\n  text-decoration: none;\r\n  font-size: 1rem;\r\n}\r\n\r\n.header-menu li a:hover {\r\n  color: rgb(194, 194, 194);\r\n}\r\n\r\nh1 {\r\n    margin: 0;\r\n}\r\n\r\n.popup {\r\n  position: fixed;\r\n  top:0;\r\n  left:0;\r\n  background-color: white;\r\n}\r\n\r\na {\r\n  transition: all 0.1s;\r\n}\r\n\r\na:hover {\r\n  text-decoration: none;\r\n}\r\n\r\n.header-title:hover {\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
