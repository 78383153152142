import React, { useState, useEffect } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css'

const CustomLink = ({ links, setLinks, index }) => {
    const [link, setLink] = useState(links[index]);
    const [errors, setErrors] = useState({}); // Error state for validation messages

    useEffect(() => {
        setLink(links[index]); // Update link state when links change
    }, [links, index]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update link state
        const newLink = {
            ...link,
            [name]: value,
        };
        setLink(newLink);
        updateLinks(newLink); // Update links state
        // Validate the current input field
        validateInput(name, value);
    };

    const validateInput = (name, value) => {
        const newErrors = { ...errors };

        // Validate link name
        if (name === "linkName") {
            if (!value && link.linkUrl) {
                newErrors.linkName = "Link name is required.";
            } else {
                delete newErrors.linkName; // Clear error if valid
            }
        }

        // Validate link URL
        if (name === "linkUrl") {
            const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
            if (value && !urlPattern.test(value)) {
                newErrors.linkUrl = "Please enter a valid URL.";
            } else if (!link.linkName){
                delete newErrors.linkUrl;
                newErrors.linkName = "Link name is required.";
            } else {
                delete newErrors.linkName; // Clear error if valid
            }
        }

        setErrors(newErrors); // Update error state
    };

    const updateLinks = (newLink) => {
        const newLinks = [...links]; // Create a new copy of links
        newLinks[index] = newLink; // Update the specific index
        setLinks(newLinks); // Update links state
    };

    return (
        <div>
            <div className="link-box">
            <input
                className='link-name'
                type="text"
                onChange={handleChange}
                name="linkName"
                placeholder='Link name'
                value={link.linkName}
            />
           

            <input
                className='link-url'
                type='url'
                onChange={handleChange}
                name="linkUrl"
                placeholder='Link URL'
                value={link.linkUrl}
            />
            
        </div>
        {errors.linkName && <span className="error">{errors.linkName}</span>} {/* Display error for link name */}
        {errors.linkUrl && <span className="error">{errors.linkUrl}</span>} {/* Display error for link URL */}
        </div>
    );
};

export default CustomLink;
