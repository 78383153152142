import React, { useState } from 'react';
import './LabeledInput.css'; // Import the CSS file

const LabeledInput = ({ label, type = 'text', name, value, onChange, placeholder, required = false }) => {
  const [error, setError] = useState(false);

  const handleBlur = () => {
    // Check if the field is required and empty
    if (required && !value) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div className="labeled-input">
      <label htmlFor={name} className="label-thin">
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className="input-field"
        required={required}
      />
    </div>
  );
};

export default LabeledInput;
