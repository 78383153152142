import React from 'react';
import Header from './header/header';

const RefundCancellation = () => {
  return (

    <div className='outer-box'>
        <Header></Header>
        <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md">
      <h1 className="text-3xl font-bold mb-6">Refunds & Cancellations</h1>
      <p className="mb-4">
        We are committed to ensuring that our customers have a great experience with our services. If you are not satisfied with your subscription, the following refund and cancellation policies apply:
      </p>
      <h2 className="text-xl font-semibold mb-2">Refund Policy</h2>
      <p className="mb-4">
        We offer refunds for our subscription plans under the following conditions:
        <ul className="list-disc list-inside mt-2 mb-4">
          <li>Refunds can be requested within 14 days of the initial purchase of a new subscription.</li>
          <li>No refunds will be issued for renewals or for any previous months of service.</li>
          <li>All approved refunds will be processed within 5-7 working days and credited to your original payment method.</li>
        </ul>
      </p>
      <h2 className="text-xl font-semibold mb-2">Cancellation Policy</h2>
      <p className="mb-4">
        You can cancel your subscription at any time. If you cancel, you will continue to have access to the service until the end of your current billing period.
      </p>
    </div>
    </div>
  );
};

export default RefundCancellation;
