import React, { useEffect, useState } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css'
import Register1 from './register1';
import Register2 from './register2';
import Register3 from './register3';
import Register4 from './register4';
import RegisterFinal from './registerFinal';
import { useParams } from 'react-router-dom';
import { getUserInfo } from '../../apis';
import Header from './../../comps/header/header'
const RegisterMain = ({isEdit}) => {
    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        experience: "",
        github: "",
        linkedin: "",
        links: [],
        password: "",
        cPassword: "",
        skills: []
    })

    useEffect(
        () => {
            (async () => {
               if (isEdit) {
                
                    const resp = await getUserInfo("")
                    setData(resp)
                
               }
            })()
        }, []
    )

    const [index, setIndex] = useState(0)

    const components = [
        <Register1 formData={data} setFormData={setData} index={index} setIndex={setIndex} isEdit={isEdit}></Register1>,
        <Register2 formData={data} setFormData={setData} index={index} setIndex={setIndex}></Register2>,
        <Register3 formData={data} setFormData={setData} index={index} setIndex={setIndex}></Register3>,
        <Register4 formData={data} setFormData={setData} index={index} setIndex={setIndex}  isEdit={isEdit}></Register4>,
        <RegisterFinal formData={data} setFormData={setData} index={index} setIndex={setIndex}></RegisterFinal>
    ]

    return (
        <div className='h-[100vh] flex items-center justify-center register-box flex-col'>
            {!isEdit && <h1 className='mb-8'>Register to get started</h1>}
                {
                    components[index]
                }
        </div>
    )
}

export default RegisterMain