// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card-icon {
    width: 7rem;
    height: 8rem;
}

.feature-card {
    width: 20rem;
    height: 23rem;
    background-color: black;
    color: white;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 2rem;
    gap: 1rem;
}

.feature-card-title  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-card-description {
    
    width: 100%;
    
}`, "",{"version":3,"sources":["webpack://./src/comps/featureCard/featureCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;;AAEf","sourcesContent":[".feature-card-icon {\r\n    width: 7rem;\r\n    height: 8rem;\r\n}\r\n\r\n.feature-card {\r\n    width: 20rem;\r\n    height: 23rem;\r\n    background-color: black;\r\n    color: white;\r\n    display: flex;\r\n    justify-content: start;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    border-radius: 5px;\r\n    padding: 2rem;\r\n    gap: 1rem;\r\n}\r\n\r\n.feature-card-title  {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.feature-card-description {\r\n    \r\n    width: 100%;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
