// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General styling for the button */
.cancel-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    margin: 0;
  }
  
  /* Image styling */
  .cancel-icon {
    width: 1.8rem; /* Adjust size as needed */
    height: 1.8rem;
  }
  

  .auth-button {
    padding: 0.8rem;
    width: 100%;
    background-color: black;
    color: white;
    font-weight: 300;
    font-family: 'Inria Sans', sans-serif;
  }

 `, "",{"version":3,"sources":["webpack://./src/components/button/CancelButt.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,UAAU;IACV,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,SAAS;EACX;;EAEA,kBAAkB;EAClB;IACE,aAAa,EAAE,0BAA0B;IACzC,cAAc;EAChB;;;EAGA;IACE,eAAe;IACf,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,qCAAqC;EACvC","sourcesContent":["/* General styling for the button */\r\n.cancel-button {\r\n    background-color: transparent;\r\n    border: none;\r\n    cursor: pointer;\r\n    padding: 0;\r\n    display: inline-flex;\r\n    align-items: center;\r\n    width: fit-content;\r\n    height: fit-content;\r\n    margin: 0;\r\n  }\r\n  \r\n  /* Image styling */\r\n  .cancel-icon {\r\n    width: 1.8rem; /* Adjust size as needed */\r\n    height: 1.8rem;\r\n  }\r\n  \r\n\r\n  .auth-button {\r\n    padding: 0.8rem;\r\n    width: 100%;\r\n    background-color: black;\r\n    color: white;\r\n    font-weight: 300;\r\n    font-family: 'Inria Sans', sans-serif;\r\n  }\r\n\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
