import React, { useState } from 'react';
import CustomLink from './link';

const LinksForm = ({ links, setLinks }) => {


    // Add a new empty link field
    const addLinkField = (e) => {
        e.preventDefault()
        setLinks([...links, { linkUrl: '', linkName: '' }]);
    };

    // Remove a link field at a particular index
    const removeLinkField = (index) => {
        const newLinks = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
    };

    return (

        <div>
            <div className='label-box'>
                <label className='label-thin'>Additional links</label>
                {
                    links.length < 3 && <button className='small-butt' onClick={addLinkField}>+</button>
                }
                
                {links.length > 0 && ( // Only show this button if more than one link is present
                    <button
                        className="small-butt"
                        onClick={(e) => {
                            e.preventDefault();
                            removeLinkField(links.length - 1);
                        }}
                    >
                        -
                    </button>
                )}
            </div>
            {
                links.length > 0 && <>
                    <div className='link-container'>
                        {links.map((link, index) => (
                            <div key={index}>
                                <CustomLink links={links} setLinks={setLinks} index={index}></CustomLink>
                            </div>
                        ))}
                    </div>
                </>
            }

        </div>

    );
};

export default LinksForm;
