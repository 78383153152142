import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getValidAccessToken, getUserInfo } from '../../apis';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import CSS
const RazorpayButtonSubscribe = ({planName}) => {
  // This function will handle the Razorpay checkout initialization
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [done ,setDone] = useState(false)
  const handleClick = async ()=> {
    const resp = await createSubscription(planName)
    const userInfo = await getUserInfo()

    if (userInfo) {
        await handlePayment(resp.id, userInfo)
    }
    
  }
  useEffect(()=> {
    if (done) {
        navigate('/myplan')
    }
  },[done])
  const createSubscription = async (planType) => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await getValidAccessToken(navigate)
      const res = await axios.post('https://backend-coverletter-ea2d7481d56b.herokuapp.com/api/payment/create-subscription', {
        planType: planType,  // Pass planType (e.g., "BASIC_TEST" or "ADVANCED_TEST")
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the token in the headers
        },
      });
      // Handle successful response
      return res.data
      
    } catch (err) {
      // Handle error
      setError('Failed to create subscription. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const verifySubscription = async (paymentResp) => {

    try {
      const accessToken = await getValidAccessToken(navigate)
      const response = await axios.post('https://backend-coverletter-ea2d7481d56b.herokuapp.com/api/payment/verify-subscription', {
        sub_id: paymentResp.razorpay_subscription_id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the token in the headers
        },
      }
    );
      if (response.data.success) {
        toast.success("Plan changed successfully.Please refresh page to see the changes!")
      }
      // Handle the response from the server
      
    } catch (err) {
      console.error('Error verifying subscription:', err);
      setError('Failed to verify subscription. Please try again.');
    } finally {
      setLoading(false); // Stop loading after request is complete
    }
  };

  const handlePayment = async (sub_id, userInfo) => {
    console.log(userInfo)
    const options = {
      key: 'rzp_live_VAjL7p0wmYsFH0', // Replace with your Razorpay key
      subscription_id: sub_id, // Replace with your subscription ID
      name: 'CoverUp',
      description: 'Monthly Test Plan',
      image: '/your_logo.jpg', // Optional: Path to your logo
      handler: verifySubscription, // Replace with your callback URL
      prefill: {
        name: `${userInfo.firstname}  ${userInfo.lastname}`, // Replace with prefill details
        email: userInfo.email,
        contact: userInfo.phone
      },
      theme: {
        color: '#F37254'
      }
    };

    const rzp1 = new window.Razorpay(options);
    
    rzp1.open();
    rzp1.on('payment.failed', function (response){
        toast.error(response.error.code);
        toast.error(response.error.description);
        toast.error(response.error.source);
        toast.error(response.error.step);
        toast.error(response.error.reason);
        toast.error(response.error.metadata.order_id);
        toast.error(response.error.metadata.payment_id);
    })
  };

  return (
    <div>
        {loading && <button className='bg-black text-white w-full'><ClipLoader color="white" size={15} /></button>}
        {!loading && <button onClick ={handleClick} className='bg-black text-white w-full'>Change Plan</button>}
        <ToastContainer />
    </div>
  );
};




export default RazorpayButtonSubscribe;
