import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Header from "../header/header";
import { useParams } from "react-router-dom";
// Renders errors or successfull transactions on the screen.
function Message({ content }) {
    return <p>{content}</p>;
}

function PayPal() {
    const { planId} = useParams()
    const initialOptions = {
        "client-id": "ATTxV3X1wZJakUijjy8F9Ywp9LxJIzY98LVhM2Bn-abnUtVg4hw6BnqfWS4z6T_By58bnFdp1lMk0Ct2",// Intent is set to subscription
        "intent": "subscription",
        "vault":  "true"
    };

    const [subscriptionMessage, setSubscriptionMessage] = useState("");

    return (
        <div className="outer-box">
          <Header></Header>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: planId, // Replace with your PayPal Plan ID
                });
              }}
              onApprove={(data, actions) => {
                // add a backend validation logic
                setSubscriptionMessage("Subscription completed successfully!");
                console.log("Subscription data: ", data);
              }}
              onError={(err) => {
                setSubscriptionMessage("Subscription failed. Please try again.");
                console.error("Subscription error: ", err);
              }}
            />
          </PayPalScriptProvider>
          {subscriptionMessage && <p>{subscriptionMessage}</p>}
        </div>
      );
    };


export default PayPal; 