import React, { useState } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css';
import LabeledInput from '../inputs/input';
import AuthButt from '../button/AuthButt';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom';

const RegisterFinal = ({ formData, setFormData, index, setIndex }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({}); // For storing error messages
  const [successMessage, setSuccessMessage] = useState(""); // For success messages
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const { password, cPassword } = formData;

    // Check password length
    if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    // Check if password and confirm password match
    if (password !== cPassword) {
      newErrors.cPassword = "Passwords do not match.";
    }

    return newErrors;
  };

  const doRegister = async (e) => {
    e.preventDefault();

    // Reset messages
    setSuccessMessage("");
    setErrors({});

    // Validate the form
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop further execution if there are errors
    }

    setLoading(true); // Start loading spinner

    try {
      // Adding a sleep of 500ms before making the API request
      await new Promise((resolve) => setTimeout(resolve, 500));

      const response = await fetch('https://backend-coverletter-ea2d7481d56b.herokuapp.com/api/user-profile/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert formData to JSON string
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('User registered successfully!'); // Set success message
        navigate('/login');
      } else {
        const errorData = await response.json();
        if (response.status === 400 && errorData.error === 'A user with this email already exists.') {
          setErrors({ ...errors, email: 'A user with this email already exists. Please use a different email.' });
        } else {
          console.error('Error registering user:', errorData);
          setErrors({ ...errors, server: 'Registration failed. Please try again.' }); // Set server error message
        }
      }
    } catch (error) {
      console.error('Request failed:', error);
      setErrors({ ...errors, network: 'Network error. Please check your connection and try again.' }); // Set network error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-box">
      <form>
        <h1 className='flex justify-center items-center text-bold'>CoverUp</h1>
        {/* Display success message */}
        <div className='message-display'>
          {successMessage && <span className="success">{successMessage}</span>}

          {/* Display error messages */}
          {errors.server && <span className="error">{errors.server}</span>}
          {errors.network && <span className="error">{errors.network}</span>}
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="form-group">
          <LabeledInput
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {errors.password && <span className="error">{errors.password}</span>} {/* Display password error */}
        </div>

        <div className="form-group last">
          <LabeledInput
            className="last"
            label="Confirm Password"
            type="password"
            name="cPassword"
            value={formData.cPassword}
            onChange={handleChange}
            required
          />
          {errors.cPassword && <span className="error">{errors.cPassword}</span>} {/* Display confirm password error */}
        </div>

        <div className="btn-grp">
          {loading ? (
            <AuthButt text={<ClipLoader color="white" size={11} />} />
          ) : (
            <AuthButt text="Register" onClick={doRegister} />
          )}
          <AuthButt
            text="Prev"
            onClick={(e) => {
              e.preventDefault();
              const newIndex = index - 1;
              setIndex(newIndex);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default RegisterFinal;
