import React from 'react';
import Header from './header/header';
const ShippingPolicy = () => {
  return (
    <div className='outer-box'>
        <Header></Header>
        <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md">
      <h1 className="text-3xl font-bold mb-6">Service Delivery Policy</h1>
      <p className="mb-4">
        As a software-as-a-service (SaaS) provider, we deliver our services digitally. Once your subscription is successfully processed, you will have immediate access to the platform and all related services.
      </p>
      <h2 className="text-xl font-semibold mb-2">Access to Services</h2>
      <p className="mb-4">
        Upon purchasing a subscription, your account will be activated immediately, and you can begin using the features included in your plan. If you encounter any issues with access, please contact our support team at <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">arankeparth1912@gmail.com</a>.
      </p>
      <h2 className="text-xl font-semibold mb-2">Upgrades and Downgrades</h2>
      <p className="mb-4">
        You can upgrade or downgrade your subscription plan at any time. Changes will take effect immediately, and any difference in charges will be prorated based on the remaining time in your current billing cycle.
      </p>
    </div>
    </div>
  );
};

export default ShippingPolicy;
