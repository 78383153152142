import React from 'react';
import './CancelButt.css'; // Import the CSS file

const AuthButt = ({ onClick, text, type = "" }) => {
  return (
    <button className="auth-button" onClick={onClick} type={type}>
        {text}
    </button>
  );
};

export default AuthButt;
