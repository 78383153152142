import React, { useState } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css';
import Header from '../header/header';
import LabeledInput from '../inputs/input';
import AuthButt from '../button/AuthButt';
import { useNavigate } from "react-router-dom";

const Register1 = ({ formData, setFormData, index, setIndex, isEdit }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({}); // For storing error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' }); // Clear error message on input change
  };

  const validateForm = () => {
    const newErrors = {};
    const { firstname, lastname } = formData;

    // Validate first name
    if (!firstname) {
      newErrors.firstname = "First Name is required.";
    } else if (firstname.length < 2) {
      newErrors.firstname = "First Name should be at least 2 characters long.";
    }

    // Validate last name
    if (!lastname) {
      newErrors.lastname = "Last Name is required.";
    } else if (lastname.length < 2) {
      newErrors.lastname = "Last Name should be at least 2 characters long.";
    }

    // Update errors state
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();

    // Validate form fields
    if (validateForm()) {
      const newIndex = index + 1;
      setIndex(newIndex);
    }
  };

  return (
    <>
    <div className="main-box">
      
      <form>  
      <h1 className='flex justify-center items-center text-bold'> CoverUp</h1>
        <div className="form-group">
          <LabeledInput
            label={"First Name"}
            type='text'
            name='firstname'
            value={formData.firstname}
            onChange={handleChange}
            required={true}
          />
          {errors.firstname && <span className="error">{errors.firstname}</span>} {/* Display first name error */}
        </div>

        <div className="form-group last">
          <LabeledInput
            className="last"
            label={"Last Name"}
            type='text'
            name='lastname'
            value={formData.lastname}
            onChange={handleChange}
            required={true}
          />
          {errors.lastname && <span className="error">{errors.lastname}</span>} {/* Display last name error */}
        </div>
        {
          !isEdit && <div className='m-1 flex justify-end mt-1 mb-[1rem]'>
          Already a user?<a href='/login'>Login</a>
          </div>
        }
        <div className='btn-grp'>
          <AuthButt text="Next" onClick={handleNext}></AuthButt>
        </div>
      </form>
    </div>
    </>
  );
};

export default Register1;
