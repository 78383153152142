// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for labeled input */
.labeled-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
  
  /* Label styling */
  .input-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #939393;
    font-weight: 300;
    font-family: 'Inria Sans', sans-serif;
    width: 100%;
  }
  
  /* Input field styling */
  .input-field {
    padding: 10px;
    border: 0px solid #ccc;
    border-radius: 4px;
    font-family: 'Inria Sans', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    background-color: #D9D9D9;
    outline: none;
  }
  
  .error-message {
    color: #996060;
    font-weight: 300;
    font-family: 'Inria Sans', sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/components/inputs/LabeledInput.css"],"names":[],"mappings":"AAEA,gCAAgC;AAChC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;EACb;;EAEA,kBAAkB;EAClB;IACE,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qCAAqC;IACrC,WAAW;EACb;;EAEA,wBAAwB;EACxB;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qCAAqC;IACrC,eAAe;IACf,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,qCAAqC;EACvC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&display=swap');\r\n\r\n/* Container for labeled input */\r\n.labeled-input {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-bottom: 15px;\r\n    width: 100%;\r\n  }\r\n  \r\n  /* Label styling */\r\n  .input-label {\r\n    margin-bottom: 5px;\r\n    font-size: 14px;\r\n    color: #939393;\r\n    font-weight: 300;\r\n    font-family: 'Inria Sans', sans-serif;\r\n    width: 100%;\r\n  }\r\n  \r\n  /* Input field styling */\r\n  .input-field {\r\n    padding: 10px;\r\n    border: 0px solid #ccc;\r\n    border-radius: 4px;\r\n    font-family: 'Inria Sans', sans-serif;\r\n    font-size: 16px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    background-color: #D9D9D9;\r\n    outline: none;\r\n  }\r\n  \r\n  .error-message {\r\n    color: #996060;\r\n    font-weight: 300;\r\n    font-family: 'Inria Sans', sans-serif;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
