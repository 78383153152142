import React from 'react';
import Header from './header/header';

const PrivacyPolicy = () => {
  return (
    <div className='outer-box'>
        <Header />
        <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md text-gray">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <p className="mb-4">
        At CoverUp, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our services. By using our website and services, you agree to the practices described in this policy.
      </p>

      <h2 className="text-xl font-semibold mb-4">1. Information We Collect</h2>
      <p className="mb-4">
        We may collect personal information from you when you register on our website, subscribe to our services, or interact with our platform. This information may include your name, email address, billing information, and other details you provide to us.
      </p>

      <h2 className="text-xl font-semibold mb-4">2. How We Use Your Information</h2>
      <p className="mb-4">
        The information we collect is used to provide, maintain, and improve our services. This may include processing your payments, communicating with you about updates, and personalizing your experience. We do not sell or share your personal information with third parties without your consent, except where required by law.
      </p>

      <h2 className="text-xl font-semibold mb-4">3. Cookies and Tracking Technologies</h2>
      <p className="mb-4">
        Our website may use cookies and similar tracking technologies to enhance your user experience. Cookies are small text files that are placed on your device to help us understand your usage patterns and improve the functionality of our services. You can adjust your browser settings to disable cookies, but doing so may affect your ability to use certain features of the website.
      </p>

      <h2 className="text-xl font-semibold mb-4">4. Data Security</h2>
      <p className="mb-4">
        We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security of your data.
      </p>

      <h2 className="text-xl font-semibold mb-4">5. Your Rights</h2>
      <p className="mb-4">
        You have the right to access, update, or delete the personal information we hold about you. If you wish to exercise these rights, please contact us at <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">arankeparth1912@gmail.com</a>.
      </p>

      <h2 className="text-xl font-semibold mb-4">6. Third-Party Links</h2>
      <p className="mb-4">
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.
      </p>

      <h2 className="text-xl font-semibold mb-4">7. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We reserve the right to update this Privacy Policy at any time. If we make significant changes, we will notify you via email or by posting a prominent notice on our website. Your continued use of our services after such changes will constitute your acceptance of the updated policy.
      </p>

      <p className="mt-6">
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">arankeparth1912@gmail.com</a>.
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
