import React, { useEffect, useState } from 'react';
import './header.css';
import { logout } from '../../apis';
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from 'react-icons/fa'; // Import Font Awesome user icon
import BuyLetters from '../payment/tokensForm';
import { getValidAccessToken } from '../../apis';

const Header = ({ showFromMain, setShowFromMain, setBalance, balance }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      const token = await getValidAccessToken();
      if (token) {
        setIsLoggedIn(true);
      }
    })();
  }, []);

  return (
    <header className="header">
      <div className="header-container">
        <h1 className="header-title" onClick={() => navigate('/')}>CoverUp</h1>
        <nav className="header-nav">
          <ul className="header-menu">
            <li><a href="/">Home</a></li>
            <li><a href="/products">Products</a></li>

            {/* Show profile icon if logged in */}
            {isLoggedIn && (
              <>
                
                <li>
                  <a href="" onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}>
                    Buy letters
                  </a>
                </li>
                <li>
                  <a href="" onClick={async (e) => {
                    e.preventDefault();
                    await logout();
                    navigate('/login');
                  }}>
                    LogOut
                  </a>
                </li>
                <li>
                  {/* Profile icon */}
                  <a href="/myplan">
                    <FaUserCircle size={24} title="Profile" style={{ cursor: 'pointer' }} />
                  </a>
                </li>
              </>
            )}

            {!isLoggedIn && (
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}>
                  LogIn
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
      {(showFromMain || show) && (
        <BuyLetters
          onClickCancel={() => {
            if (setShowFromMain) {
              setShowFromMain(false);
            }
            setShow(false);
          }}
          setBalance={setBalance}
        />
      )}
    </header>
  );
};

export default Header;
