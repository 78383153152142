import React, { useState } from 'react';
import '../loginForm.css'; // Import the CSS file
import '../styles.css';
import Header from '../header/header';
import LabeledInput from '../inputs/input';
import AuthButt from '../button/AuthButt';

const Register3 = ({ formData, setFormData, index, setIndex }) => {
  const [prompt, setPrompt] = useState(formData.experience);
  const [errors, setErrors] = useState({});
  const [newSkills, setSkills] = useState(formData.skills || []);
  const [newSkill, setNewSkill] = useState(''); // To store the current skill being typed

  const handlePromptChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setPrompt(value);
  };

  const handleNewSkillChange = (e) => {
    setNewSkill(e.target.value);
  };

  const addSkill = () => {
    if (newSkill.trim()) {
      setSkills([...newSkills, newSkill.trim()]);
      setFormData({ ...formData, skills: [...newSkills, newSkill.trim()] });
      setNewSkill(''); // Clear input after adding
    }
  };

  const removeSkill = (skillToRemove) => {
    const updatedSkills = newSkills.filter(skill => skill !== skillToRemove);
    setSkills(updatedSkills);
    setFormData({ ...formData, skills: updatedSkills });
  };

  const validateExperience = () => {
    const newErrors = {};
    if (!prompt.trim()) {
      newErrors.experience = "Experience description is required.";
    }
    if (prompt.length > 1000) {
      newErrors.experience = "Experience description cannot exceed 1000 characters.";
    }
    return newErrors;
  };

  const handleNext = (e) => {
    e.preventDefault();
    const validationErrors = validateExperience();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIndex(index + 1);
  };

  return (
    <div className="main-box enable-scroll">
      <form>
        <h1 className="flex justify-center items-center text-bold">CoverUp</h1>
        <label className="label-thin mb-[1rem]">
          Tell us about your work experience.(Max 1000 characters)
          <span className="required-asterisk">*</span>
        </label>
        <textarea
          value={prompt}
          onChange={handlePromptChange}
          className="promt-input exp"
          name="experience"
        />
        {errors.experience && <span className="error">{errors.experience}</span>}

        {/* Skills section */}
        <div className="skills-section">
          <label className="label-thin mb-[1rem]">Add your skills:</label>
          <div className="skill-input-container flex flex-row mb-4 items-center gap-2">
            <input
              type="text"
              value={newSkill}
              onChange={handleNewSkillChange}
              className="skill-input text-black bg-gray [2.8rem] m-0"
            />
            <button type="button" onClick={addSkill} className="add-skill-button h-[2.7rem] bg-black text-white flex justify-center items-center p-0 px-4 text-[2rem]">
              +
            </button>
          </div>

          {/* Display added skills */}
          <div className="skills-list flex flex-row gap-2 mb-4 flex-wrap">
            {newSkills.map((skill, index) => (
              <div key={index} className="skill-item flex flex-row h-[40px] relative">
                <div className='w-fit text-white flex justify-center items-center px-4 skill-name'>{skill}

               
                </div>
                <button
                  type="button"
                  onClick={() => removeSkill(skill)}
                  className="absolute top-[-4px] right-[-8px] text-white bg-red-500 hover:bg-red-700 rounded-full w-[20px] h-[20px] flex justify-center items-center text-xs p-0"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="btn-grp">
          <AuthButt text="Next" onClick={handleNext}></AuthButt>
          <AuthButt
            text="Prev"
            onClick={(e) => {
              e.preventDefault();
              setIndex(index - 1);
            }}
          ></AuthButt>
        </div>
      </form>
    </div>
  );
};

export default Register3;
