import './App.css';
import LoginForm from './components/LoginForm';
import RegisterMain from './components/Register/registerMain';
import Home from './components/HomeComponent.js/Home';
import Register2 from './components/Register/register2';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register3 from './components/Register/register3';
import Register4 from './components/Register/register4';
import PrivacyPolicy from './comps/privacy';
import TermsOfService from './comps/terms';
import SubscriptionPlan from './comps/planCards/CurrPlan';
import PayPal from './comps/payment/payPal';
import BuyTokens from './comps/payment/Tokens';
import BuyLetters from './comps/payment/tokensForm';
import Footer from './comps/Footer';
import PrivateRoute from './PrivateRoute';
import EditComp from './comps/Edit';
import Register from './comps/Register';
import RefundCancellation from './comps/refundpolicy';
import ShippingPolicy from './comps/shippingPolicy';
import Pricing from './comps/pricing';
import Products from './comps/products';
function App() {
  return (
    <Router className="App">
      <Routes>
        {/* Public Routes with Footer */}
        <Route path="/login" element={<><LoginForm /><Footer /></>} />
        <Route path="/register" element={<><Register /><Footer /></>} />
        <Route path="/refund" element={<><RefundCancellation /><Footer /></>} />
        <Route path="/shipping" element={<><ShippingPolicy /><Footer /></>} />
        <Route path="/" element={<><Home /><Footer /></>} />
        <Route path="/pricing" element={<><Pricing /><Footer /></>} />
        <Route path="/products" element={<><Products /></>} />
        {/* Public Privacy and Terms Routes */}
        <Route path="/privacy" element={<><PrivacyPolicy /><Footer /></>} />
        <Route path="/terms" element={<><TermsOfService /><Footer /></>} />

        {/* Protected Routes wrapped in PrivateRoute with Footer */}
        <Route 
          path="/editUserInfo" 
          element={<PrivateRoute><><EditComp /><Footer /></></PrivateRoute>} 
        />
        <Route 
          path="/myPlan" 
          element={<PrivateRoute><><SubscriptionPlan /><Footer /></></PrivateRoute>} 
        />
        <Route 
          path="/buyPlan/:planId" 
          element={<PrivateRoute><><PayPal quantity={2} /><Footer /></></PrivateRoute>} 
        />
        <Route 
          path="/buyTokens" 
          element={<PrivateRoute><><BuyLetters /><Footer /></></PrivateRoute>} 
        />
        <Route 
          path="/payBuyTokens/:quantity" 
          element={<PrivateRoute><><BuyTokens /><Footer /></></PrivateRoute>} 
        />
      </Routes>
    </Router>
  );
}

export default App;
