import React, { Component, useEffect} from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getValidAccessToken } from "../../apis";
import { withRouter } from "react-router-dom";
import Header from "../header/header";
import './payment.css'
import { useNavigate } from 'react-router-dom';

function withNavigation(Component) {
    return function (props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

class BuyTokens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            message: "",
            initialOptions: {
                "client-id": "ATTxV3X1wZJakUijjy8F9Ywp9LxJIzY98LVhM2Bn-abnUtVg4hw6BnqfWS4z6T_By58bnFdp1lMk0Ct2",
                currency: "USD",
                components: "buttons",
            }
        };
    }

    isLoggedIn = () => {
        const accessToken = localStorage.getItem("accessToken");
        return !!accessToken;
    }

    createOrder = async () => {
        const { quantity } = this.props;
        const baseUrl = "https://backend-coverletter-ea2d7481d56b.herokuapp.com";
        try {
            const response = await fetch(baseUrl + "/api/orders", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    quantity: quantity,
                }),
            });

            const orderData = await response.json();

            if (orderData.id) {
                return orderData.id;
            } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: `Could not initiate PayPal Checkout...${error}` });
        }
    }

    onApprove = async (data, actions) => {
        const { quantity } = this.props;
        const baseUrl = "https://backend-coverletter-ea2d7481d56b.herokuapp.com";
        const { navigate } = this.props;
        const accessToken = await getValidAccessToken(navigate);

        try {
            const response = await fetch(
                `${baseUrl}/api/orders/${data.orderID}/capture/${quantity}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const orderData = await response.json();
            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
            } else if (errorDetail) {
                throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`
                );
            } else {
                const transaction = orderData.purchase_units[0].payments.captures[0];
                if (transaction.status === "COMPLETED") {
                    navigate('/myplan');
                }
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: `Sorry, your transaction could not be processed...${error}` });
        }
    }

    componentDidUpdate(prevProps) {
        // Compare current props with previous props
        if (this.props.quantity !== prevProps.quantity) {
            // Call forceUpdate when props change
            this.forceUpdate();
        }
    }

    render() {
        const { initialOptions, message } = this.state;
        return (
            <div className="">
                <div className="w-[18rem]">
                    <div>
                        <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                style={{
                                    shape: "rect",
                                    layout: "vertical",
                                    color: "gold",
                                    label: "paypal",
                                }}
                                createOrder={this.createOrder}
                                onApprove={this.onApprove}
                            />
                        </PayPalScriptProvider>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNavigation(BuyTokens);
