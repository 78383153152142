import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../comps/header/header';
import FeatureCard from '../../comps/featureCard/feature';
import Footer from '../../comps/Footer';
const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // Initially logged out
  const isDone = useRef(false)
  const [desc, setDesc] = useState(null)
  const[resp, setResp] = useState("")
  const navigate = useNavigate()
  // Simulate data fetching or authentication process
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     const accessToken = localStorage.getItem('accessToken');
  //     const refreshToken = localStorage.getItem('refreshToken');
  //     const username = localStorage.getItem('username');
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken)
    if (accessToken) {
      setIsLoggedIn(true)
    } else {
      // navigate('/login')
    }
  }, []);

  return (
    < div className=''>
      <Header isLoggedIn={isLoggedIn}></Header>
      <div className='outer-box h-'>
        
        <h1>AI-Crafted Cover Letters for Upwork</h1> 

        <p className='mt-[1rem]'>
        Seamlessly tailor every application with personalized enhancements and custom add-ons to stand out in every job search.
        </p>
        {
          !isLoggedIn && <button className='plans-butt  mt-[1.5rem]' onClick={() => {
            navigate('/register')
          }}>
          Start for free
        </button>
        }
        {
          isLoggedIn && <button className='plans-butt mt-[1.5rem]' onClick={() => {
            navigate('/myplan')
          }}>
         Upgrade your plan
        </button>
        }
        <div className='features-container'>
        <FeatureCard iconUrl={"Relax.png"} description={"Automatically generate tailored cover letters and auto fills them based on job descriptions with a single click."} title={"Effortless Cover Letters"}></FeatureCard>
        <FeatureCard iconUrl={"Time Machine.png"} description={"Enter your details once, and the extension will handle future cover letters automatically"} title={"One-Time Setup"}></FeatureCard>
        <FeatureCard iconUrl={"Support.png"} description={"Personalize your cover letters with custom add-on prompt snippets  to make each application unique, ensuring your profile stands out."} title={"Customizable Add-ons"}></FeatureCard>
        </div>
        
      </div>
    </div>
  );
  

};

export default Home;
