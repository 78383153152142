import React from "react";
import '../styles.css'
import CancelButton from "../button/cancelButt";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
    return(
        <div className='header'>
          <h1>
            Register
          </h1>
      </div>
    )
}

export default Header