import React from 'react';
import Header from './header/header';
const TermsOfService = () => {
  return (
    <div className='outer-box'>
        <Header></Header>
        <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md text-gray">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      
      <p className="mb-4">
        Welcome to CoverUp! These terms and conditions outline the rules and regulations for using our services. By accessing or using our website and services, you agree to comply with these terms. If you do not agree with any part of these terms, you must not use our services.
      </p>

      <h2 className="text-xl font-semibold mb-4">1. User Accounts</h2>
      <p className="mb-4">
        To access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to provide accurate, current, and complete information during the registration process.
      </p>

      <h2 className="text-xl font-semibold mb-4">2. Subscription and Payments</h2>
      <p className="mb-4">
        Our services are provided on a subscription basis. By subscribing, you agree to pay the fees associated with the selected plan. Subscription fees are billed in advance on a monthly or annual basis, depending on the plan you choose. If payment is not completed, your access to the services may be suspended or terminated.
      </p>
      <p className="mb-4">
        You may cancel your subscription at any time, and you will retain access to the services until the end of the current billing period. Refunds for canceled subscriptions will not be provided for any unused portion of the subscription period.
      </p>

      <h2 className="text-xl font-semibold mb-4">3. Use of Services</h2>
      <p className="mb-4">
        You agree to use our services only for lawful purposes. You are prohibited from using our services to:
        <ul className="list-disc list-inside mt-2 mb-4">
          <li>Engage in any illegal or fraudulent activities</li>
          <li>Distribute malware, viruses, or other harmful code</li>
          <li>Interfere with or disrupt the integrity or performance of the services</li>
          <li>Attempt to gain unauthorized access to our systems or networks</li>
        </ul>
      </p>

      <h2 className="text-xl font-semibold mb-4">4. Intellectual Property</h2>
      <p className="mb-4">
        All content, trademarks, and other intellectual property available through our services are the exclusive property of CoverUp or its licensors. You may not use, reproduce, or distribute any of our intellectual property without prior written consent.
      </p>

      <h2 className="text-xl font-semibold mb-4">5. Modifications to Services</h2>
      <p className="mb-4">
        We reserve the right to modify, suspend, or discontinue our services at any time, with or without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the services.
      </p>

      <h2 className="text-xl font-semibold mb-4">6. Limitation of Liability</h2>
      <p className="mb-4">
        To the fullest extent permitted by law, CoverUp will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of the services or any errors or interruptions in the services.
      </p>

      <h2 className="text-xl font-semibold mb-4">7. Governing Law</h2>
      <p className="mb-4">
        These terms and conditions are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from or relating to these terms will be resolved in the courts of [Insert Jurisdiction].
      </p>

      <h2 className="text-xl font-semibold mb-4">8. Changes to Terms</h2>
      <p className="mb-4">
        We may update these terms from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website. Your continued use of the services after the changes have been made will constitute your acceptance of the new terms.
      </p>

      <p className="mt-6">
        If you have any questions about these terms and conditions, please contact us at <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">arankeparth1912@gmail.com</a>.
      </p>
    </div>
    </div>
  );
};

export default TermsOfService;
