// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General container for the form */
.form-container {
    max-width: 20rem;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 10px;
  }
  
  /* Input styling */
  .form-input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Button styling */
  .form-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .login-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .register-button {
    background-color: #28a745;
    color: #fff;
  }
  
  /* Add hover effects for buttons */
  .form-button:hover {
    opacity: 0.9;
  }
  

  .auth-box {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-box {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid gray;
  }

 `, "",{"version":3,"sources":["webpack://./src/components/loginForm.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB;EACxB;;EAEA,uBAAuB;EACvB;IACE,mBAAmB;EACrB;;EAEA,kBAAkB;EAClB;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA,mBAAmB;EACnB;IACE,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA,kCAAkC;EAClC;IACE,YAAY;EACd;;;EAGA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,0CAA0C;IAC1C,sBAAsB;EACxB","sourcesContent":["/* General container for the form */\r\n.form-container {\r\n    max-width: 20rem;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    background-color: #fff;\r\n  }\r\n  \r\n  /* Form group styling */\r\n  .form-group {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  /* Input styling */\r\n  .form-input {\r\n    width: 100%;\r\n    padding: 8px;\r\n    margin-top: 4px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  /* Button styling */\r\n  .form-button {\r\n    width: 100%;\r\n    padding: 10px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .login-button {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n  }\r\n  \r\n  .register-button {\r\n    background-color: #28a745;\r\n    color: #fff;\r\n  }\r\n  \r\n  /* Add hover effects for buttons */\r\n  .form-button:hover {\r\n    opacity: 0.9;\r\n  }\r\n  \r\n\r\n  .auth-box {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n\r\n  .main-box {\r\n    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);\r\n    border: 1px solid gray;\r\n  }\r\n\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
