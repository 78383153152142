import React from 'react';
import './CancelButt.css'; // Import the CSS file

const CancelButton = ({ onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <img src="Cancel.png" alt="Cancel" className="cancel-icon" />
    </button>
  );
};

export default CancelButton;
