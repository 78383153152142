import { createContext, useContext, useEffect, useState } from 'react';
import { getValidAccessToken } from './apis';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null); // Null when not authenticated

//   const login = (userData) => setUser(userData);
//   const logout = () => setUser(null);
  
  useEffect(() => {
    (async () => {
        const newToken = await getValidAccessToken()
        setToken(newToken)
    })()
  })

  return (
    <AuthContext.Provider value={{ token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
