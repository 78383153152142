import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css'; // Import the CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
import '../loginForm.css'; // Import the CSS file
import '../styles.css';
import Header from '../header/header';
import LabeledInput from '../inputs/input';
import AuthButt from '../button/AuthButt';

const Register2 = ({ formData, setFormData, index, setIndex }) => {
  const [errors, setErrors] = useState({}); // For storing error messages

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' }); // Clear error message on input change
  };

  const validateForm = () => {
    const newErrors = {};
    const { email, phone } = formData;

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!emailPattern.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Validate phone number (check for at least 10 digits, excluding country code)
    const minPhoneLength = 10; // Minimum number length (excluding country code)
    if (!phone) {
      newErrors.phone = "Phone number is required.";
    } else if (phone.replace(/\D/g, '').length < minPhoneLength) {
      newErrors.phone = `Phone number must be at least ${minPhoneLength} digits long.`;
    }

    // Update errors state
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();

    // Validate form fields
    if (validateForm()) {
      const newIndex = index + 1;
      setIndex(newIndex);
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    const newIndex = index - 1;
    setIndex(newIndex);
  };

  return (
    <div className="main-box">
      <form>
        <h1 className='flex justify-center items-center text-bold'>CoverUp</h1>
        <div className="form-group">
          <LabeledInput
            label={"Email"}
            type='text'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required={true}
          />
          {errors.email && <span className="error">{errors.email}</span>} {/* Display email error */}
        </div>

        <div className="form-group last">
          {/* Phone number input with country code */}
          <h1 className='label-thin'>Phone*</h1>
          <PhoneInput
            country={'in'} // Set default country to India
            value={formData.phone || ''} // Handle phone input state
            onChange={(phone) => setFormData({ ...formData, phone })} // Update formData with phone
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true
            }}
            inputStyle={{
              backgroundColor: "#D9D9D9",
              width: "100%",
              color: "black",
              height: "2.5rem"
            }}
          />
          {errors.phone && <span className="error">{errors.phone}</span>} {/* Display phone error */}
        </div>

        <div className='btn-grp'>
          <AuthButt text="Next" onClick={handleNext}></AuthButt>
          <AuthButt text="Prev" onClick={handlePrev}></AuthButt>
        </div>
      </form>
    </div>
  );
};

export default Register2;
