import React from 'react';
import Header from './header/header';
import { useNavigate } from 'react-router-dom';
const Pricing = () => {
    const navigate = useNavigate()
  return (
    <div className="outer-box">
      <Header />
      <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md text-gray">
        <h1 className="text-3xl font-bold mb-6">Pricing Plans</h1>
        
        <p className="mb-4">
          We offer flexible subscription plans to suit your needs. Whether you’re looking for a basic plan or a more comprehensive option, we have you covered. All prices are in INR.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-100 p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">Basic Plan</h2>
            <p className="mb-4">Perfect for freshers.</p>
            <p className="text-2xl font-bold mb-4">₹50 / month</p>
            <ul className="list-disc list-inside mb-4">
              <li>40 letter generations per month</li>
              <li>No customizable prompt addOns</li>
            </ul>
            <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700" onClick={()=>{
            navigate('/myplan')
          }}>
              Get Started
            </button>
          </div>

          <div className="bg-gray-100 p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">Advanced</h2>
            <p className="mb-4">Best for experienced freelancers</p>
            <p className="text-2xl font-bold mb-4">₹100 / month</p>
            <ul className="list-disc list-inside mb-4">
              <li>100 letter generations per month</li>
              <li>Customizable prompt addOns</li>
            </ul>
            <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700" onClick={()=>{
            navigate('/myplan')
          }}>
              Get Started
            </button>
          </div>
        </div>

        {/* New section for buying letters directly */}
        <div className="mt-8 p-4 bg-gray-100 rounded-md shadow-md text-center">
          <h2 className="text-xl font-semibold mb-4">Buy Letters Directly</h2>
          <p className="mb-4">Not ready for a subscription? You can also purchase letters individually.</p>
          <p className="text-2xl font-bold mb-4">₹3 per 1 letters</p>
          <button  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700" onClick={()=>{
            navigate('/myplan')
          }}>
            Buy Now
          </button>
        </div>

        <p className="mt-6">
          Need a custom solution? Contact us at <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">arankeparth1912@gmail.com</a> for more information on tailored pricing options.
        </p>
      </div>
    </div>
  );
};

export default Pricing;
