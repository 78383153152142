import Header from "./header/header";
import Footer from "./Footer";
const Products = () => {
    return (
        <div className="outer-box">
            <Header />
            <div className="container mx-auto my-10 p-6 bg-white rounded-md shadow-md text-gray-800">
                <h1 className="text-3xl font-bold mb-6">Our Products</h1>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-100 p-6 rounded-md shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Upwork Cover Letter Generator</h2>
                        <p className="mb-4">
                            Save time and boost your chances of landing the perfect job with our AI-powered Upwork Cover Letter Generator. 
                            Generate high-quality, personalized cover letters based on your past experience and projects in seconds!
                        </p>
                        <ul className="list-disc list-inside mb-4">
                            <li>Generate up to 100 cover letters per month</li>
                            <li>Customizable prompt addons</li>
                            <li>Easy to integrate with Upwork</li>
                        </ul>
                        <a href="https://chromewebstore.google.com/detail/coverup/bijlkedghoeeogidehoegmialpdbmneg?authuser=0&hl=en" className="text-blue-600 hover:underline">
                        Download extension
                    </a>
                    </div>

                    {/* Additional products can go here */}
                </div>

                <p className="mt-6">
                    For more details about our extension, visit our{' '}
                    <a href="https://www.coverlettergeneratorupwork.xyz/" className="text-blue-600 hover:underline">
                        website
                    </a> or contact us at{' '}
                    <a href="mailto:arankeparth1912@gmail.com" className="text-blue-600 hover:underline">
                        arankeparth1912@gmail.com
                    </a>.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default Products;