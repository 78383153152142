// Function to get the accessToken from localStorage

function getAccessToken() {
    return localStorage.getItem('accessToken');
}

// Function to get the refreshToken from localStorage
function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

// Function to set the accessToken in localStorage
function setAccessToken(token) {
    localStorage.setItem('accessToken', token);
}

// Function to set the RefreshToken in localStorage
function setRefreshToken(token) {
    localStorage.setItem('RefreshToken', token);
}

// Function to clear tokens from localStorage (for logout)
function clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.clear()
}

const baseUrl = "https://backend-coverletter-ea2d7481d56b.herokuapp.com"

// API to get user info
async function getUserInfo(username) {
    try {
        // Get access token from localStorage
        let accessToken = getAccessToken();

        // First request to fetch user info
        let response = await fetch(`${baseUrl}/api/user-profile/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}` // Send access token in the header
            }
        });

        if (response.status === 401 || response.status === 403) {
            // Access token expired, refresh token needed
            console.log('Access token expired. Trying to refresh token...');

            const refreshToken = getRefreshToken();
            if (!refreshToken) {
                throw new Error('No refresh token available.');
            }
            // Request to refresh the access token
            const refreshResponse = await fetch(`${baseUrl}/api/auth/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ refreshToken }) // Send refresh token in body
            });

            if (refreshResponse.ok) {
                const refreshData = await refreshResponse.json();

                // Update the access token in localStorage
                setAccessToken(refreshData.accessToken);

                // Retry the original request with the new access token
                response = await fetch(`${baseUrl}/api/user-profile/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${refreshData.accessToken}` // Use new access token
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                throw new Error('Unable to refresh access token.');
            }
        }

        // Parse and return the user info
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
}

async function logout() {
    try {
        // Step 1: Get the access token from localStorage (this checks if the user is authenticated)
        let accessToken = getAccessToken();

        if (!accessToken) {
            console.error('No access token available. User is not authenticated.');
            return;
        }

        // Step 2: Get refresh token from localStorage
        let refreshToken = getRefreshToken();
        if (!refreshToken) {
            throw new Error('No refresh token available.');
        }

        // Step 3: Send request to logout (invalidate refresh token on server)
        let response = await fetch(`${baseUrl}/api/auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}` // Send access token in headers
            },
            body: JSON.stringify({ refreshToken }) // Send refresh token in body
        });

        // Step 4: If access token expired or invalid, refresh token and retry
        if (response.status === 401 || response.status === 403) {
            console.log('Access token expired or invalid. Attempting to refresh token...');

            // Attempt to refresh the access token
            const refreshResponse = await fetch(`${baseUrl}/api/auth/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ refreshToken }) // Send refresh token in body
            });

            if (refreshResponse.ok) {
                const refreshData = await refreshResponse.json();

                // Update tokens in localStorage
                setAccessToken(refreshData.accessToken);

                // Retry the original logout request with the new access token
                accessToken = getAccessToken(); // Get the updated access token
                response = await fetch(`${baseUrl}/api/auth/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${refreshData.accessToken}` // Use the updated access token
                    },
                    body: JSON.stringify({ refreshToken }) // Send refresh token in body
                });

                if (!response.ok) {
                    throw new Error(`Logout failed after refreshing token! Status: ${response.status}`);
                }

                // Clear tokens from localStorage on successful logout
                clearTokens();
                console.log('Logout successful after token refresh!');
            } else {
                throw new Error('Unable to refresh access token.');
            }
        }

        // Step 5: If the initial request was successful (no need to refresh token)
        if (response.ok) {
            // Clear tokens from localStorage on successful logout
            clearTokens();
            console.log('Logout successful!');
        }

    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
}

const Update = async (formData) => {
    // Validate the form
   
    let token = getAccessToken(); // Get access token from storage

    // If no access token, return an authorization error
    if (!token) {
        return { error: { authorization: 'No token found. Please log in again.' } };
    }

    try {
        let response = await fetch(`${baseUrl}/api/user-profile/update`, {
            method: 'PUT', // Use PUT or PATCH for updates
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Add the Authorization header
            },
            body: JSON.stringify(formData), // Convert formData to JSON string
        });

        // If access token is expired, refresh the token
        if (response.status === 401) {
            console.log("Access token expired. Attempting to refresh...");

            const refreshToken = getRefreshToken(); // Assuming you store refresh token

            if (!refreshToken) {
                return { error: { authorization: 'Session expired. Please log in again.' } };
            }

            // Attempt to refresh the access token
            const refreshResponse = await fetch(`${baseUrl}/api/auth/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refreshToken }), // Send the refresh token
            });

            if (refreshResponse.ok) {
                const refreshData = await refreshResponse.json();
                token = refreshData.accessToken;
                setAccessToken(token); // Save the new access token in storage

                // Retry the original update request with the new token
                response = await fetch(`${baseUrl}/api/user-profile/update`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Use the refreshed token
                    },
                    body: JSON.stringify(formData),
                });
            } else {
                return { error: { authorization: 'Failed to refresh token. Please log in again.' } };
            }
        }

        // Check if update was successful
        if (response.ok) {
            const data = await response.json();
            return { success: 'User profile updated successfully!', data }; // Return success message and data
        } else {
            const errorData = await response.json();
            console.error('Error updating user:', errorData);
            return { error: { server: 'Update failed. Please try again.', details: errorData } };
        }
    } catch (error) {
        console.error('Request failed:', error);
        return { error: { network: 'Network error. Please check your connection and try again.', details: error } };
    }
};

function isTokenExpired(token) {
    if (!token) return true;

    const [, payload] = token.split('.'); // JWT structure is header.payload.signature
    if (!payload) return true;

    const decodedPayload = JSON.parse(atob(payload));
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

    // Check if token has expired (exp is the expiration time in the token)
    return decodedPayload.exp < currentTime;
}


// Function to refresh access token using the refresh token
// Function to refresh access token using the refresh token
async function refreshAccessToken() {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
        return null
    }

    // Check if the refresh token is valid
    if (isTokenExpired(refreshToken)) {
        return null
    }

    try {
        const response = await fetch(`${baseUrl}/api/auth/refresh-token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refreshToken }),
        });

        const data = await response.json();

        if (response.ok && data.accessToken) {
            // Successfully obtained a new access token
            setAccessToken(data.accessToken);
            return data.accessToken;
        } else {
            throw new Error("Failed to refresh access token.");
        }
    } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error;
    }
}
// Main function to get a valid access token
async function getValidAccessToken(navigate) {
    let accessToken = getAccessToken();

    // Check if access token is expired
    if (isTokenExpired(accessToken)) {
        console.log("Access token expired. Attempting to refresh...");

        // Try to refresh the access token using the refresh token
        try {
            accessToken = await refreshAccessToken();
            if (!accessToken && navigate) {
                navigate('/login')
            } else if(!accessToken) return null
            console.log("Successfully refreshed access token.");
        } catch (error) {
            console.error("Failed to refresh access token. User needs to log in again.")
            clearTokens(); // Optionally clear tokens if refresh fails
            
            if (navigate) {
                navigate('/login')
            }
            return null
            throw error; // You can redirect to login or handle it another way
        }
    }

    return accessToken;
}

export { getUserInfo, logout, Update, getValidAccessToken };
