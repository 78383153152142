import React, { useState } from "react";
import BuyTokens from "./Tokens";
import { useNavigate } from "react-router-dom";
import './payment.css'
import CancelButton from "../../components/button/cancelButt";
import RazorpayButton from "./razorpay";
import RazorpayButtonTokens from "./tokenButton";
function BuyLetters({ onClickCancel, setBalance, balance }) {
    const [quantity, setQuantity] = useState(5); // Default quantity is 1
    const [message, setMessage] = useState("");
    const [amount, setAmount] = useState(5)
    const navigate = useNavigate()

    // Handle form submission
    const handleQuantityChange = (e) => {

    };

    return (
        <div className="popup-box">
            <div className="w-[20rem] pay-popup">
                <div className="flex flex-col items-center mb-[1rem]">

                    <form className="flex  flex-col ">
                        <div className="flex flex-row justify-between items-center">
                            <label className="">Number of letters to buy:</label>
                            <CancelButton onClick={onClickCancel}></CancelButton>
                        </div>
                        <div className="flex flex-row items-center gap-[0.5rem] mt-[1rem]">
                            <input
                                type="text"
                                id="quantity"
                                name="quantity"
                                value={quantity}
                                className="h-[2.5rem] m-0"
                            />
                            <button className="bg-black text-[1.5rem] w-[2rem] h-[2.5rem] flex items-center justify-center" onClick={(e) => {
                                e.preventDefault()
                                const newQ = quantity + 5
                                const newA = amount + 5
                                setQuantity(newQ);
                                setAmount(newA)
                                
                                
                            }}>+</button>
                            <button className="bg-black text-[1.5rem]  w-[2rem] h-[2.5rem] flex items-center justify-center" onClick={(e) => {
                                e.preventDefault()
                                if (quantity > 5) {
                                    
                                    setQuantity(quantity - 5);
                                    setAmount(amount - 5)
                                }
                                
                            }}>-</button>
                        </div>
                    </form>
                </div>
                <RazorpayButtonTokens quantity={quantity} setBalance={setBalance} balance={balance}></RazorpayButtonTokens>
            </div>
        </div>
    );
}

export default BuyLetters;
