// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: rgb(189, 189, 189);
    outline: none;
}

.pay-popup {
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-box {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000000000000000000000000000;
    transition: all 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/comps/payment/payment.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,oCAAoC;IACpC,qCAAqC;IACrC,oBAAoB;AACxB","sourcesContent":["input {\r\n    width: 100%;\r\n    padding: 8px;\r\n    margin-top: 4px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box;\r\n    background-color: rgb(189, 189, 189);\r\n    outline: none;\r\n}\r\n\r\n.pay-popup {\r\n    background-color: rgb(255, 255, 255);\r\n    border-radius: 6px;\r\n    padding: 1rem;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.popup-box {\r\n    height: 100vh;\r\n    width: 100vw;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: fixed;\r\n    top: 0;\r\n    background-color: rgba(0, 0, 0, 0.7);\r\n    z-index: 1000000000000000000000000000;\r\n    transition: all 0.2s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
