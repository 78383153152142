import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from './authProvider';
import { getValidAccessToken } from './apis';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const PrivateRoute = ({ children, isRegister}) => {
//   const { token } = useAuth();
  
//   if (!token) {
//     // If not authenticated, redirect to the login page
//     return <Navigate to="/login" />;
//   }
    const navigate = useNavigate()
    const [token, setToken]= useState(null)
    const {isEdit} = useParams()
    useEffect(()=> {
        
        (async() => {
            const nT = await getValidAccessToken()
            console.log(nT)
            if (nT === null) {
                if (isRegister) {
                    console.log("register")
                    if (isEdit === "true") {
                        navigate('/login')
                    } else {
                        setToken(true)
                    }
                   
                } else {
                    navigate('/login')
                }
            }
            else {
                setToken(nT)
            }
            
        })()

    },[])

  return (
    <>
    {
        token && children
    }
    </>
  ); // If authenticated, show the protected route
};

export default PrivateRoute;