import React  from 'react';
import './planCard.css'; // Make sure to style it in a separate CSS file
import { useNavigate } from 'react-router-dom';
import RazorpayButtonSubscribe from '../payment/razorpay';
import axios from 'axios'
import { getValidAccessToken } from "../../apis";
import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { TRIAL_PLAN } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import CSS
const disableSubscription = async (navigate, setIsLoading) => {
    try {
        const token = await getValidAccessToken(navigate)
        const response = await axios.post(`https://backend-coverletter-ea2d7481d56b.herokuapp.com/api/payment/cancelSubscription`, null, {
            headers: {
                Authorization: `Bearer ${token}` // Add Bearer token here
            }
        });
        console.log(response.data);
        if (response.data.status) {
          toast.success("Subscription cancelled! please refresh to see changes!")
        } else {
          toast.error(response.data.message)
        }
        // Handle success (e.g., show a success message, refresh data, etc.)
    } catch (error) {
        console.error('Error disabling subscription:', error.response?.data?.message || error.message);
        // Handle error (e.g., show an error message)
    } finally {
      setIsLoading(false)
    }
};

const PlanCard = ({ title, price, features, currPlan}) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
    {
      currPlan &&  <div className={`plan-card flex flex-col ${currPlan === TRIAL_PLAN ? 'justify-end' : 'justify-center'} flex-grow h-[18rem]`}>
      <div className='text-white flex flex-col items-center justify-center gap-2 mb-[1rem]'>
      <p className="text-[2rem] h-full text-white">Rs {price}/month</p>
      <ul className="plan-features text-white">
        {features.map((feature, index) => (
          <li key={index} className='flex flex-row gap-2 justify-center items-center'><img src={feature.icon} className='w-7 h-6'></img>{feature.text}</li>
        ))}
      </ul>
      </div>
      
      { title !== TRIAL_PLAN &&
        <>
        {
        !isLoading && <button className='bg-black text-white' onClick={(e)=> {
          e.preventDefault()
          setIsLoading(true)
          disableSubscription(navigate, setIsLoading)
        }}>Cancel subscription</button>
      
      }
      {
        isLoading && <button className='bg-black text-white'
        ><ClipLoader color="white" size={15} /></button>
      
      }
        </>
      }

    </div>
    }
    {
      !currPlan && <div className="plan-card flex flex-col justify-between flex-grow">
      <div className='text-white flex flex-row items-end justify-center gap-2'>
      <p className="text-[2rem] h-full text-white">Rs {price}/month</p>
      </div>
      <ul className="plan-features text-white">
        {features.map((feature, index) => (
          <li key={index} className='flex flex-row gap-2 justify-center items-center'><img src={feature.icon} className='w-7 h-6'></img>{feature.text}</li>
        ))}
      </ul>
     {
      <RazorpayButtonSubscribe planName={title}></RazorpayButtonSubscribe>
     }
    </div>
    }
    <ToastContainer />
    </>
  );
};

export default PlanCard;
